/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import "./drop-zone.scoped.scss";
import md5 from "md5";
import CircularProgress from "@mui/material/CircularProgress";
import upArrow from "./assets/uparrow.png";
import {
  AsperaEvent,
  AsperaServiceInterface,
  FileFilter,
  Files,
  File,
} from "../../services/aspera";
import { Button } from "../form/button";
import { getClientPlatform } from "../../utils/getClientPlatform";

export type DropZoneProps = {
  /** The main text that will be shown on the component. */
  title: string;
  /** Description text shown on the component. */
  description?: string;
  /** Text to use on the component button. */
  btnText?: string;
  /** Allow the user to upload multiple files.  */
  multiple?: boolean;
  /** The type of files supported by the drag and drop area. */
  allowedFileTypes?: FileFilter[];
  /** Loading indicator of component */
  loading?: boolean;
  /** Aspera service object */
  asperaService?: AsperaServiceInterface;
  className?: string;
  onFilesSelected?: (files: DropZoneFileDTO[]) => void;
};

export type DropZoneFileDTO = {
  /** The absolute path for the file provided by Aspera client */
  path: string;
  /** The file name including extension */
  name: string;
  /** The file size */
  size: number;
  /** File MIME type */
  type: string;
  /** Automatically generated value hashing path value */
  id: string;
  // eslint-disable-next-line camelcase
  file_id?: string;
};

/**
 * This functional component creates an area that allows the user to open the
 * Aspera client modal to select files as well as enable the drag and drop
 * feature to catch this event when the user drops files inside the area marked
 * by the dashes.
 * @param {DropZoneProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const DropZone = (props: DropZoneProps): JSX.Element => {
  const {
    title,
    description,
    btnText,
    multiple,
    allowedFileTypes,
    loading,
    asperaService,
    className,
    onFilesSelected,
  } = props;

  const dropZone = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  /** State keys to avoid step re rendering. */
  const [randClassName] = useState(`drop-area-${md5(`${Math.random()}`)}`);

  /** Destructuring the AsperaService instance from the global scope. */
  const AsperaServiceInstance = (asperaService ||
    window.AsperaService) as AsperaServiceInterface;

  const makeFileObjectFromPath = (file: File): DropZoneFileDTO => {
    const { name, size, type } = file;
    const path = name;
    const components =
      getClientPlatform() === "windows" ? path.split("\\") : path.split("/");
    return {
      path,
      name: components[components.length - 1],
      size,
      type,
      id: md5(path),
    };
  };

  /** Placeholder funtion to catch the files added to the component. */
  const filesSelectedHandler = (files: File[]): void => {
    if (!multiple && files.length > 1) {
      // ToDO: Add error message about limit in files.
      return;
    }
    const fileObjects: DropZoneFileDTO[] = files.map(
      (file: File): DropZoneFileDTO => makeFileObjectFromPath(file)
    );
    if (onFilesSelected) {
      onFilesSelected(fileObjects);
    }
  };

  useEffect(() => {
    /**
     * Set the drag and drop area where the Aspera ConnectInstance will listen
     * for user interaction.
     */
    AsperaServiceInstance?.ConnectInstance?.setDragDropTargets(
      `.${randClassName}`,
      { drop: true },
      (event: AsperaEvent): void => {
        /**
         * Destructuring the aspera event to select only the required data.
         */
        const {
          files: {
            dataTransfer: { files },
          },
        } = event;
        /** Send the files data to the handler. */
        filesSelectedHandler(files);
      }
    );
  });

  const openAsperaFileDialog = (): void => {
    /** Create the dialog options object to open the select dialog. */
    const dialogOptions = {
      allowMultipleSelection: multiple,
      // SCRNS-3550: Remove undefined allowedFileTypes. Check is done using naming convention rules
      // allowedFileTypes,
    };
    /** Trigger the event to open the select dialog. */
    AsperaServiceInstance.ConnectInstance?.showSelectFileDialog(
      {
        success: (dialog: Files) => {
          /** Send the files data to the handler. */
          filesSelectedHandler(dialog.dataTransfer.files);
        },
        error: () => {
          // (err: string) => {
          // ToDo: handle error.
        },
      },
      dialogOptions
    );
  };

  return (
    <div
      className={`drop-zone ${randClassName} ${className || ""}`}
      ref={dropZone}
    >
      {loading ? (
        <>
          <CircularProgress sx={{ color: "#8b8b8b" }} />
          <h2 className={`drop-zone-text-loading ${className || ""}`}>
            Loading file...
          </h2>
        </>
      ) : (
        <>
          <img
            className={`drop-zone-image ${className || ""}`}
            src={upArrow}
            alt=""
          />
          <div className={`drop-zone-image-text ${className || ""}`}>
            <h2 className={`drop-zone-text-title ${className || ""}`}>
              {title}
            </h2>
            {description && (
              <p className={`drop-zone-text-description ${className || ""}`}>
                {description}
              </p>
            )}
          </div>
          <Button value={btnText || ""} onClick={openAsperaFileDialog} />
        </>
      )}
    </div>
  );
};

DropZone.defaultProps = {
  btnText: "Select Files",
  multiple: true,
  allowedFileTypes: undefined,
  loading: false,
  asperaService: undefined,
  className: "",
  description: undefined,
  onFilesSelected: undefined,
};
