import { GenericResponse } from "../../core/types/http-service.types";
import { ChildEntity, SearchTitleType, TitleVersion } from "./title-api.types";

export interface Facet {
  type?: number;
  id?: string;
  name: string;
  value: any;
  from?: string;
  to?: string;
  selected?: boolean;
}

export enum ProductType {
  COMPILATION_EPISODE = 2,
  DIRECT_TO_VIDEO = 3,
  EPISODE = 4,
  FEATURE = 5,
  FORMAT = 6,
  MAKING_OF = 7,
  MINISERIES = 8,
  OMT = 9,
  PILOT = 10,
  PRESENTATION = 11,
  SEASON = 12,
  SERIES = 13,
  SHORT = 14,
  SPECIAL = 15,
  UNSCRIPTED_FORMAT = 16,
  SCRIPTED_FORMAT = 17,
  WORKSPACE = 18,
  NEW_MEDIA_FILM = 19,
  MASH_UP = 20,
  MFT_MOW = 21,
  EVENT = 22,
  EVENT_LV2 = 23,
  EVENT_LV3 = 24,
}

export const SeriesChildren: ProductType[] = [
  ProductType.COMPILATION_EPISODE,
  ProductType.EPISODE,
  ProductType.PILOT,
  ProductType.SEASON,
];

export const ParentProductTypes: ProductType[] = [
  ProductType.MINISERIES,
  ProductType.SERIES,
];

export const EpisodeTitles: ProductType[] = [
  ProductType.COMPILATION_EPISODE,
  ProductType.EPISODE,
  ProductType.PILOT,
];

export const SearchableProductTypes: ProductType[] = [
  ProductType.COMPILATION_EPISODE,
  ProductType.EPISODE,
  ProductType.PILOT,
  ProductType.SEASON,
  ProductType.SERIES,
  ProductType.FEATURE,
  ProductType.EVENT,
  ProductType.EVENT_LV2,
  ProductType.EVENT_LV3,
];

export const SearchableProductTypesByKeyword: ProductType[] = [
  ProductType.DIRECT_TO_VIDEO,
  ProductType.FEATURE,
  ProductType.MINISERIES,
  ProductType.OMT,
  ProductType.SERIES,
  ProductType.SHORT,
  ProductType.SPECIAL,
  ProductType.MASH_UP,
  ProductType.MFT_MOW,
  ProductType.EVENT,
];

export const EventProductTypes: ProductType[] = [
  ProductType.EVENT,
  ProductType.EVENT_LV2,
  ProductType.EVENT_LV3,
];

export enum titleStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export const ProductTypeDSMap: { [k in ProductType]: SearchTitleType } = {
  [ProductType.FEATURE]: "feature",
  [ProductType.MINISERIES]: "miniseries",
  [ProductType.SERIES]: "series",
  [ProductType.SHORT]: "short",
  [ProductType.SPECIAL]: "special",
  [ProductType.EPISODE]: "episode",
  [ProductType.SEASON]: "season",
  [ProductType.PILOT]: "pilot",
  [ProductType.COMPILATION_EPISODE]: "compilation episode",
  [ProductType.DIRECT_TO_VIDEO]: "unknown",
  [ProductType.FORMAT]: "unknown",
  [ProductType.MAKING_OF]: "unknown",
  [ProductType.OMT]: "unknown",
  [ProductType.PRESENTATION]: "unknown",
  [ProductType.UNSCRIPTED_FORMAT]: "unknown",
  [ProductType.SCRIPTED_FORMAT]: "unknown",
  [ProductType.WORKSPACE]: "unknown",
  [ProductType.NEW_MEDIA_FILM]: "unknown",
  [ProductType.MASH_UP]: "unknown",
  [ProductType.MFT_MOW]: "unknown",
  [ProductType.EVENT]: "event",
  [ProductType.EVENT_LV2]: "event",
  [ProductType.EVENT_LV3]: "event",
};

export interface Version {
  versionId: number;
  description: string;
}

export interface TitleSearchQuery {
  keyword?: string;
  fields?: {
    id?: string;
    name: string;
    value: any;
  }[];
  facets?: Facet[];
  sortDescriptors?: {
    direction: string;
    field: string;
  }[];
  pageIndex?: number;
  pageSize?: number;
  sortOptionId?: number;
  includeFacets?: boolean;
}

export interface TitleItem {
  id: string;
  productId: number;
  type: {
    id: ProductType;
    name: string;
  };
  name: string;
  season?: TitleItem;
  series?: TitleItem;
  versions?: Version[];
  sequenceNumber: number;
  status?: titleStatus;
  releaseDate: string;
  applicationTitle?: {
    id: string;
    name: string;
    status: titleStatus;
    startDate: string;
    endDate: string;
    releaseDate: string;
    sequenceNumber: number;
    children: any[];
  };
  supplementalTitleName?: string;
}

export interface SearchResult {
  items: TitleItem[];
  totalItemCount: number;
  pageIndex: number;
  pageSize: number;
}

export type DmdcChildEntity = ChildEntity & {
  id?: string;
  versions?: TitleVersion[];
  productTypeId?: ProductType;
};

export type SearchResponse = GenericResponse<SearchResult>;

export type DSScreenerTypeValues =
  | "finalScreener"
  | "trailerForRatings"
  | "promo"
  | "finalTrailer"
  | "featurette"
  | "bRoll"
  | "preliminaryScreener"
  | "clip"
  | "tvSpot"
  | "tags"
  | "interview";

export interface DSScreenerTypeOptions {
  name: string;
  value: DSScreenerTypeValues;
}

export const FinalScreener: DSScreenerTypeOptions = {
  name: "Final Screener",
  value: "finalScreener",
};

export const PreliminaryScreener: DSScreenerTypeOptions = {
  name: "Preliminary Screener",
  value: "preliminaryScreener",
};

export const TrailerForRatings: DSScreenerTypeOptions = {
  name: "Trailer for Ratings",
  value: "trailerForRatings",
};

export const FinalTrailer: DSScreenerTypeOptions = {
  name: "Final Trailer",
  value: "finalTrailer",
};

export const Promo: DSScreenerTypeOptions = {
  name: "Promo",
  value: "promo",
};

export const screenerTypeOptions: DSScreenerTypeOptions[] = [
  FinalScreener,
  PreliminaryScreener,
  TrailerForRatings,
  FinalTrailer,
  Promo,
  {
    name: "Featurette",
    value: "featurette",
  },
  {
    name: "B-Roll",
    value: "bRoll",
  },
  {
    name: "Clip",
    value: "clip",
  },
  {
    name: "TV Spot",
    value: "tvSpot",
  },
  {
    name: "Tags",
    value: "tags",
  },
  {
    name: "Interview",
    value: "interview",
  },
];
