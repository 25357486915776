/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, forwardRef, ReactElement } from "react";
import "./input.scoped.scss";

export type InputProps = {
  /** The initial value for the input. */
  value: string;
  /** Extra css classes to be passed to the input wrapper container. */
  className: string;
  /** An adornment placed at the right of the input. */
  startAdornment: ReactElement;
  /** Set the disabled state for the input. */
  disabled: boolean;
  /** An id property to be assigned to the inner input. */
  id: string;
  /** Event handler for changes on the input value. */
  onChange: (value: string) => void;
  [x: string]: any;
};

/**
 * This component is a wrapper for form inputs, the intentions is to encapsulate
 * all the required logic to generate specific input formats, like: text, date,
 * numeric, etc. For now it only supports a common input (string).
 * @param {InputProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
// eslint-disable-next-line react/display-name
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const { value, onChange, startAdornment, disabled } = props;
    /** Internal state to store the input value. */
    const [stateValue, setStateValue] = useState(value || "");
    const [prevStateValue, setPrevStateValue] = useState(value || "");

    const setNewAndPrevValue = (newValue: string): void => {
      setStateValue((v) => {
        setPrevStateValue(v);
        return newValue;
      });
    };

    useEffect(() => {
      if (stateValue !== value) {
        setNewAndPrevValue(value || "");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
      if (stateValue !== prevStateValue && onChange) {
        onChange(stateValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateValue]);

    const onChangeInternal = (event: any): void => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setNewAndPrevValue(event.target.value);
    };

    const [newProps, setNewProps] = useState({});
    useEffect(() => {
      const skip = ["onChange", "value", "startAdornment", "disabled"];
      const tempProps: any = {};
      Object.keys(props).forEach((k: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (skip.includes(k)) return;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        tempProps[k] = (props as any)[k];
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!tempProps.type) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        tempProps.type = "text";
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setNewProps(tempProps);
    }, [props]);

    return (
      <div className={`input-field ${disabled ? "disabled" : ""}`}>
        {startAdornment ? (
          <div className="start-adornment">{startAdornment}</div>
        ) : null}
        <input
          ref={ref}
          {...newProps}
          onChange={onChangeInternal}
          value={stateValue}
        />
      </div>
    );
  }
);
