// eslint-disable-next-line max-len
import DigitalScreenersMediaManagerWebComponent from "./DigitalScreenersMediaManager";

/**
 * Creates a new DigitalScreenersUploadWidget instance
 * @class
 */

export class DigitalScreenersUploadWidget extends DigitalScreenersMediaManagerWebComponent {}

export default DigitalScreenersMediaManagerWebComponent;
