import React from "react";
import { Router, Route, Routes } from "react-router-dom";
import { MainMenuView } from "../../views/main-menu";
import { NewPackageView } from "../../views/new-package";
import { UpdatePackageView } from "../../views/update-package/update-package.view";
import "./upload-widget.scoped.scss";

/**
 * This functional component is the global wrapper for all the
 * child sections presented on the widget.
 * @returns {JSX.Element}
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UploadWidget = (props: any): JSX.Element => (
  <div>
    {/* A <Switch> looks through its children <Route>s and
     * renders the first one that matches the current URL. */}
    <Routes>
      <Route path="/new-package" element={<NewPackageView />} />
      <Route
        path="/update-package/:packageId?"
        element={<UpdatePackageView />}
      />
      <Route path="/" element={<MainMenuView />} />
    </Routes>
  </div>
);
