import {
  TitleItem,
  ProductType,
  ProductTypeDSMap,
  DmdcChildEntity,
  titleStatus,
  Version,
} from "../services/title-api/dmdc-title-api.types";
import {
  TitleVersion,
  SearchTitleType,
  ChildEntity,
} from "../services/title-api/title-api.types";

export const mapProductType = (type: ProductType): SearchTitleType => {
  return ProductTypeDSMap[type] || "unknown";
};

export const mapProductTypeByTypeName = (typeName: string) => {
  const existsNameAsProductType = Object.entries(ProductType).find(
    ([key, value]) => key === typeName.toUpperCase()
  );

  if (!existsNameAsProductType) {
    console.log(`item.type [${typeName}]`);
    return "unknown";
  }
  const productType: ProductType =
    ProductType[typeName.toUpperCase() as keyof typeof ProductType];
  return mapProductType(productType);
};

export const mapProductTypeByAny = (item: TitleItem) => {
  if (item.type?.id in ProductType) {
    return mapProductType(item.type.id);
  }

  if (!item.type?.id && typeof item.type === "string") {
    return mapProductTypeByTypeName(item.type);
  }

  console.log(
    `item.type.id [${item.type?.id}], item.type [${JSON.stringify(item.type)}]`
  );

  return "unknown";
};

export const mapVersion = (version: Version): TitleVersion => ({
  name: version?.description,
  versionId: version?.versionId?.toString(),
});

export const mapRootTitle = (titleItem: TitleItem): DmdcChildEntity => {
  return {
    id: titleItem.id,
    productId: titleItem.productId,
    name: titleItem.name,
    type: mapProductTypeByAny(titleItem),
    versions: titleItem.versions?.map(mapVersion),
    isActive: titleItem.applicationTitle?.status === titleStatus.ACTIVE,
    productTypeId: titleItem.type.id,
    releaseDate: titleItem.releaseDate,
  };
};

export const mapSeasonTitle = (titleItem: TitleItem): DmdcChildEntity => {
  const sequence =
    titleItem.sequenceNumber || titleItem.applicationTitle?.sequenceNumber;
  return {
    id: titleItem.id,
    productId: titleItem.productId,
    name: titleItem.name,
    type: mapProductTypeByAny(titleItem),
    seasonNumber: sequence?.toString(),
    sequence: sequence,
    versions: titleItem.versions?.map(mapVersion),
    productTypeId: titleItem.type.id,
    releaseDate: titleItem.releaseDate,
  };
};

export const mapEpisodeTitle = (titleItem: TitleItem): DmdcChildEntity => {
  const sequence =
    titleItem.sequenceNumber || titleItem.applicationTitle?.sequenceNumber;
  return {
    id: titleItem.id,
    productId: titleItem.productId,
    name: titleItem.name,
    type: mapProductTypeByAny(titleItem),
    episodeNumber: sequence?.toString(),
    seasonEpisodeNumber: sequence?.toString(),
    sequence: sequence,
    seriesName: titleItem.season?.name,
    versions: titleItem.versions?.map(mapVersion),
    productTypeId: titleItem.type.id,
    releaseDate: titleItem.releaseDate,
  };
};

export const mapChildEntity = (
  title: DmdcChildEntity | undefined
): ChildEntity => {
  const { versions, productTypeId, id, ...newTitle } = title || {};

  return newTitle as ChildEntity;
};
