/* eslint-disable react/jsx-props-no-spreading */
import { BottomNavigation } from "@mui/material";
import React, { MouseEventHandler, ReactNode } from "react";
import "./button.scoped.scss";

type TypeButtonThemes = {
  [key: string]: string;
};

const BUTTON_THEMES: TypeButtonThemes = {
  default: "",
  boxed: "boxed",
};

export type ButtonProps = {
  /** Text value for the button. */
  value: string | ReactNode;
  /** Extra class names to use on the button. */
  className?: string;
  /** Theme of the button. */
  variant?: string;
  /** Set the button to a disabled state. */
  disabled?: boolean;
  /** Event handler for click. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Property for testing purposes */
  buttonId?: string;
};

/**
 * This is the base Button component to work as the foundation for all the
 * types of buttons found in the application.
 * @param {ButtonProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const Button = (props: ButtonProps): JSX.Element => {
  const { value, className, disabled, onClick, buttonId, variant } = props;

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = (
    event
  ): void => {
    /**
     * If button is enabled and a click handler was provided it will be
     * executed.
     * */
    if (!disabled && onClick) {
      onClick(event);
    }
  };

  return (
    <button
      type="button"
      className={`button ${className || ""} ${
        BUTTON_THEMES[variant || "default"]
      }`}
      disabled={disabled}
      onClick={onClickHandler}
      id={buttonId}
    >
      {value}
    </button>
  );
};

Button.defaultProps = {
  className: "",
  disabled: false,
  onClick: undefined,
  buttonId: "",
  variant: undefined,
};
